const { createMuiTheme } = require("@material-ui/core");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#26ace2"
    },
    secondary: {
      main: "#ff7a00"
    },
    containedPrimary: {
      color: "#ffffff"
    }
  },
});

export default theme;
