import React from "react";
import FlexCenter from "../components/layout/FlexCenter";
import { Typography } from "@material-ui/core";
import { useAuth } from "../services/AuthProvider";
import { Navigate } from "react-router-dom";
import logo from "../assets/logo-massy-united-insurance.jpg";
import LoginForm from "../components/forms/LoginForm";

const Landing = () => {
  const { login, role, error } = useAuth();

  const formLogin = (values) => {
    login(values);
  };

  if (role) {
    return <Navigate to={`${role}`} />;
  }
  return (
    <FlexCenter height={"100vh"} column justify="start">
      <div style={{ marginTop: "2em" }}>
        <img src={logo} alt="Logo Massy United" width="600" />
      </div>
      <div style={{ padding: "4em 0", color: "#064c78", textAlign: "center" }}>
        <Typography variant="h4" component="h4">
          Massy ANVA Converter
        </Typography>
        <Typography variant="h4" omponent="span" style={{ color: "#f58220" }}>
          {process.env.REACT_APP_VERSION}
        </Typography>
      </div>
      <LoginForm submit={formLogin} error={error} />
    </FlexCenter>
  );
};

export default Landing;
