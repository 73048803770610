import axios from "axios";
const API = process.env["REACT_APP_API"] || "";

export const fetchData = async (path, token) => {
  return await getRequest(path, token);
};

export const apiLogin = async (credentials) => {
  return await postRequest("/auth/local", credentials);
};

export const updateStatus = async (id, uploadInfo, token) => {
  return await putRequest(
    `/anva-files/update-status/${id}`,
    uploadInfo,
    token
  );
}

export const addAuditTrail = async (data, token) => {
  return await postRequest(`/audit-trails`, data, token);
}

export const saveAntwoord = async (id, dosis, antwoord, token) => {
  return await putRequest(
    `/patients/save-antwoord/${dosis}/${id}`,
    antwoord,
    token
  );
};

export const prikken = async (id, data, token) => {
  return await putRequest(`/patients/prikken/${id}`, data, token);
};

export const prikkenBatch = async (data, token) => {
  return await postRequest(`/patients/prikken/batch`, data, token);
};

export const cleanResident = async (id, token) => {
  return await putRequest(`/patients/clean/${id}`, {}, token);
};
export const resetVacc2Resident = async (id, token) => {
  return await putRequest(
    `/patients/${id}`,
    {
      dosis: 1,
      status: 7,
      antwoordenD2: {},
    },
    token
  );
};

export const saveInwoner = async (id, inwoner, token) => {
  return await putRequest(`/patients/${id}`, inwoner, token);
};

export const nieuwInwoner = async (inwoner, token) => {
  return await postRequest(`/patients`, inwoner, token);
};
export const deleteResident = async (inwoner, token) => {
  return await deleteRequest(`/patients/${inwoner}`, token);
};

export const downloadData = async (type, from_date, token) =>
  await postRequest(
    `/patients/download/${type}`,
    { from_date },
    token,
    API,
    "blob"
  );

const getRequest = async (path, token = undefined) => {
  try {
    const headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const { data } = await axios.get(`${API}${path}`, {
      headers,
    });
    return data;
  } catch (error) {
    console.error(error.message);
    return [];
  }
};

export const postRequest = async (
  path,
  requestBody,
  token = undefined,
  api = API,
  responseType = "json"
) => {
  try {
    const headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const { data } = await axios.post(`${api}${path}`, requestBody, {
      headers,
      responseType,
    });
    return data;
  } catch (error) {
    console.error(error.message);
    return undefined;
  }
};

export const putRequest = async (path, requestBody, token = undefined) => {
  try {
    const headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const { data } = await axios.put(`${API}${path}`, requestBody, {
      headers,
    });
    return data;
  } catch (error) {
    console.error(error.message);
    return undefined;
  }
};
export const deleteRequest = async (path, token = undefined) => {
  try {
    const headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const { data } = await axios.delete(`${API}${path}`, {
      headers,
    });
    return data;
  } catch (error) {
    console.error(error.message);
    return undefined;
  }
};

export const fetcher = (url) =>
  axios.get(url).then((response) => response.data);
