import React from "react";
import MenuAppBar from "./MenuAppBar";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import { Helmet } from "react-helmet";

import logo from "../../assets/logo-massy-sm.png";
import ibisLogo from "../../assets/ibis-logo.png";
import MainNavigation from "./MainNavigation";
import FlexCenter from "./FlexCenter";
import { useAuth } from "../../services/AuthProvider";
import FixedPosition from "./FixedPosition";
import { Typography } from "@material-ui/core";

const drawerWidth = 200;

const Layout = ({ children, title, padding = true, centered = false }) => {
  const classes = useStyles({ padding });
  const { role } = useAuth();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Massy ANVA | {role} </title>
      </Helmet>
      <MenuAppBar className={classes.appBar} title={title} />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        height="100vh"
      >
        <div className={classes.toolbar}>
          <div className={classes.imgcon}>
            <img
              className={classes.img}
              src={logo}
              alt="Logo"
              width={drawerWidth - 30}
              style={{}}
              // height={"128px"}
            />
          </div>
        </div>
        <Divider />
        <MainNavigation drawerWidth={drawerWidth} />
        <FixedPosition drawerWidth={drawerWidth}>
          <div style={{ margin: "1em" }}>
            <img src={ibisLogo} alt="Logo of IBIS" />
            <Typography align="center">
              {process.env.REACT_APP_VERSION || "local development"}
            </Typography>
          </div>
        </FixedPosition>
      </Drawer>
      {centered && (
        <main className={classes.centered}>
          <FlexCenter column>{children}</FlexCenter>
        </main>
      )}
      {!centered && <main className={classes.content}>{children}</main>}
    </div>
  );
};

export default Layout;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "white",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowY: "hidden",
  },
  drawerPaper: {
    backgroundColor: "#064c78",
    color: "white",
    width: drawerWidth,
    overflowY: "hidden",
  },
  imgcon: {
    padding: "1em ",
    backgroundColor: "#fff",
  },
  img: {
    backgroundColor: "#fff",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: (props) => (props.padding ? theme.spacing(3) : 0),
    marginTop: 64,
    // height: "80vh",
  },
  centered: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: (props) => (props.padding ? theme.spacing(3) : 0),
    marginTop: 64,
    height: "calc(100vh-200px)",
  },
}));
