import {
  Button,
  Card,
  CardActions,
  CardContent,
  Icon,
  Typography,
  makeStyles
} from "@material-ui/core";
import { DoneAll, Warning, Publish, } from "@material-ui/icons";
import React from "react";
import FlexSpaceBetween from "../../components/layout/FlexSpaceBetween";

import mcbLogo from "../../assets/mcb.png";
import bdcLogo from "../../assets/bdc.png";
import FlexCenter from "../../components/layout/FlexCenter";
import { getFriendlyTimeStamp } from "../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#26ace2",
    color: "white",
    "&:hover": {
      backgroundColor: "#007faa",
      color: "white",
    },
    paddingLeft: 40,
    paddingRight: 40,
  },
  disabled: {
    
  },
}));

const AnvaCard = ({ data, user, kies, size = "small", setUploadStatus }) => {
  const {
    id,
    batchNumber,
    outputType,
    total,
    created_at,
    anvaRecords,
    status,
    errorMessage,
  } = data;

  const userViewer = "VIEWER";

  const statusError = "ERROR";
  const statusConverted = "CONVERTED";
  const statusUploaded = "UPLOADED";

  const naamVariant = size === "small" ? "h6" : "h4";
  const txtVariant = size === "small" ? "body1" : "h6";
  const totalRecords = anvaRecords && anvaRecords.length;
  
  const isUploadedBtnDisabled = status === statusUploaded || status === statusError || user.role.name === userViewer;
  const isDownloadBtnDisabled = status === statusError || user.role.name === userViewer;
  const isShowRecordsBtnDisabled = status === statusError;

  const showStatus = () => {
    if (status === statusError) {
      return status + ": " + errorMessage;
    }
    return status;
  };

  const defineTextColorAndIcon = () => {
    let color;
    let statusIcon;

    switch (
      status
    ) {
      case statusError:
        color = "#f50057";
        statusIcon = <Warning />;
        break;
      case statusConverted:
        color = "#008900";
        statusIcon = <DoneAll />;
        break;
      case statusUploaded:
        color = "#0D4DA5";
        statusIcon = <Publish />;
        break;
      default:
        color = "#000000";
        break;
    }
    return {
      style: { color: color, marginTop: "0.3em" },
      statusIcon
    };
  };

  return (
    <Card style={{ display: "grid", gridTemplateColumns: "1fr 3fr", padding: "1.1em", borderRadius: 12, boxShadow: "0 4px 12px -6px rgba(0,0,0,0.35)", }}>
      <FlexCenter style={{ padding: "1em" }}>
        {outputType === "MCB_FEP" ? (
          <img src={mcbLogo} alt="Logo of MCB" />
        ) : (
          <img src={bdcLogo} alt="Logo of BDC" />
        )}
      </FlexCenter>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent>
          <FlexSpaceBetween>
            <Typography variant={naamVariant}>
            {outputType} - {batchNumber}
            </Typography>
          </FlexSpaceBetween>
          <>
            <Typography variant={txtVariant} style={{ marginTop: "0.35em" }}>
              {getFriendlyTimeStamp(created_at)}
            </Typography>
            <Typography variant={txtVariant} style={defineTextColorAndIcon().style}>
              <Icon style={{ paddingTop: "0.25em" }}>{defineTextColorAndIcon().statusIcon}</Icon> {showStatus()}
            </Typography>
            <Typography variant={txtVariant} style={{ marginTop: "0.35em" }}>
              Records: {totalRecords}
            </Typography>
            <Typography variant={naamVariant} style={{ marginTop: "0.3em" }}>Total: {total}</Typography>
          </>
        </CardContent>
        <CardActions>
          <FlexSpaceBetween>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => kies("show", data)}
              disabled={isShowRecordsBtnDisabled}
            >
              Show Records {totalRecords}
            </Button>
            <Button
              id={id}
              variant="outlined"
              color="secondary"
              onClick={() => {
                kies("upload", data);
                setUploadStatus({"fileId": data.id});
              }}
              disabled={isUploadedBtnDisabled}
            >
              Uploaded to Bank
            </Button>
            <Button
              className={useStyles().root}
              variant="contained"
              color="primary"
              onClick={() => kies("download", data)}
              disabled={isDownloadBtnDisabled}
            >
              DownLoad
            </Button>
          </FlexSpaceBetween>
        </CardActions>
      </div>
    </Card>
  );
};

export default AnvaCard;
