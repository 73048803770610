import React from "react";

import { Divider, Typography } from "@material-ui/core";
import GridAutoFill from "../../components/layout/GridAutoFill";
import AnvaCard from "./AnvaCard";
import { cardTitle, getFriendlyDate } from "../../services/utils";
import { groupBy } from "lodash";

const AnvaCardsList = ({ user, loadStatus = 'success', list = [], kies = () => {}, setUploadStatus }) => {
  if (loadStatus === 'loading') {
    return <Typography>Loading...</Typography>;
  }
  if (loadStatus === 'error') {
    return <Typography>An error occurred. Please try again.</Typography>;
  }
  if (list.length === 0) {
    return <Typography>No Records Available</Typography>;
  }

  const data = list.map((item) => ({
    ...item,
    creationDate: cardTitle(item.created_at),
  }));
  const perDate = groupBy(data, "creationDate");
  return (
    <>
      {Object.keys(perDate).map((date, idx) => (
        <div key={idx}>
          <Divider style={{ marginTop: "3em" }} />
          <Typography variant="h4" align="center" style={{ marginTop: ".4em", marginBottom: ".4em" }}>
            {getFriendlyDate(date)}
          </Typography>
          <Divider style={{ marginBottom: "1.5em" }} />
          <GridAutoFill px={600} gap="1.5em">
            {perDate[date].map((file) => (
              <AnvaCard key={file.id} user={user} data={file} kies={kies} setUploadStatus={setUploadStatus} />
            ))}
          </GridAutoFill>
        </div>
      ))}
    </>
  );
};

export default AnvaCardsList;
